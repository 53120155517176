import styled from 'styled-components';
import { fluidRange } from 'polished';

const Section = styled.section`
  background: ${({ theme, color }) =>
    color === 'orange' ? theme.colors.orangeMedium : null};
  ${fluidRange(
    {
      prop: 'padding-top',
      fromSize: '60px',
      toSize: '160px',
    },
    '380px',
    '1400px'
  )};
  ${fluidRange(
    {
      prop: 'padding-bottom',
      fromSize: '60px',
      toSize: '160px',
    },
    '380px',
    '1400px'
  )};
`;

export default Section;
