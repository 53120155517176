import React from 'react';

const Marque = () => (
  <svg width={52} height={52}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M52 26C52 11.64 40.36 0 26 0S0 11.64 0 26s11.64 26 26 26 26-11.64 26-26"
        fill="currentColor"
      />
      <path
        fill="#FFF"
        d="M26.453 12.923v3.946h6.075v2.564h-6.075v5.41h-2.947V10.36h9.524v2.563zM17.342 30.92h-2.501v3.877h-3.822v2.561h3.822v3.878h2.501v-3.878h3.822v-2.56h-3.822V30.92zm-.501.509v3.877H20.662v1.544H16.842V40.727h-1.499V36.85H11.521v-1.544H15.342v-3.877h1.499zM26.418 30.858v3.946h6.076v2.563h-6.076v5.411h-2.947V28.294h9.524v2.564z"
      />
    </g>
  </svg>
);

export default Marque;
