import { Link } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Grid from '../common/Grid';
import { P } from '../common/Typography';
import Logo from '../icons/Logo';

import { fadeIn } from '../../utils/keyframes';

import { email } from '../../../config';

const DESKTOP = 1100;

const Wrapper = styled.header`
  ${({ absolute }) =>
    absolute &&
    css`
      @media (min-width: ${DESKTOP}px) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
      }
    `}
`;

const Inner = styled(Grid)`
  @media (max-width: ${DESKTOP - 1}px) {
    padding: 20px 0;
    grid-row-gap: 12px;
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: ${DESKTOP}px) {
    padding: 40px 0;
    display: flex;
  }
`;

const Nav = styled.nav`
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 4vw;
  margin-left: auto;
`;

const NavLink = styled(Link)`
  color: ${({ theme }) => theme.colors.orangeDark};
  &:visited {
    color: ${({ theme }) => theme.colors.orangeDark};
  }
  @media (max-width: 799px) {
    font-size: 16px;
    line-height: 30px;
  }
  @media (min-width: 800px) {
    font-size: 22px;
    line-height: 30px;
  }
`;

const HeaderLogo = styled(Logo)`
  @media (max-width: 599px) {
    height: 40px;
  }
  @media (min-width: 600px) {
    height: 60px;
  }
  @media (min-width: 900px) {
    height: 84px;
  }
`;

const Premise = styled(P)`
  @media (max-width: ${DESKTOP - 1}px) {
    grid-column: 1 / span 2;
    grid-row: 2;
    max-width: 390px;
    padding-left: 16px;
  }
  @media (max-width: 799px) {
    margin-bottom: 50px;
  }
  @media (min-width: 600px) {
    max-width: 426px;
    padding-left: 24px;
  }
  @media (min-width: 900px) {
    max-width: 426px;
    padding-left: 33px;
  }
  @media (min-width: ${DESKTOP}px) {
    padding-left: 28px;
    border-left: 3px solid;
    width: 412px;
    margin: 0 0 0 7.5%;
    animation: ${fadeIn} 1s ease;
  }
`;

export default function Header({ homepage }) {
  return (
    <Wrapper absolute={homepage}>
      <Inner>
        <Link to="/">
          <HeaderLogo />
        </Link>
        {homepage && (
          <Premise>
            An international collective offering branding and digital services
            to responsible organizations
          </Premise>
        )}
        <Nav>
          <NavLink to="/about">About us</NavLink>
          <NavLink as="a" href={`mailto:${email}`}>
            Contact
          </NavLink>
        </Nav>
      </Inner>
    </Wrapper>
  );
}

Header.propTypes = {
  homepage: PropTypes.bool,
};
