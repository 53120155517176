import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    background-repeat: no-repeat;
  }

  html {
    font-family: NeueMontreal, sans-serif;
    color: ${({ theme }) => theme.colors.orangeDark};
    background: ${({ theme }) => theme.colors.orangeLight}; 
    overflow-y: scroll;
  }

  body {
    margin: 0;
    scroll-behavior: smooth;
  }

  audio,
  canvas,
  iframe,
  img,
  svg,
  video  {
    max-width: 100%;
    vertical-align: middle;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }

  p a {
    border-bottom: 2px solid;
    color: currentColor;
  }

  [alt=""] {
    pointer-events: none;
  }

  textarea {
    resize: vertical;
  }
`;
