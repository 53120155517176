import styled from 'styled-components';

const Grid = styled.div`
  display: ${({ flex }) => (flex ? 'flex' : 'grid')};
  grid-column-gap: 2.5%;
  max-width: 1440px;
  grid-template-columns: ${({ flex }) =>
    flex ? null : 'repeat(12, minmax(0, 200px))'};
  width: 90%;
  margin: 0 auto;
`;

export default Grid;
