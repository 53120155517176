import styled from 'styled-components';
import { fluidRange } from 'polished';

export const H1 = styled.h1`
  margin: 0;
  font-weight: 100;
  line-height: 1.2em;
  ${fluidRange(
    {
      prop: 'font-size',
      fromSize: '26px',
      toSize: '58px',
    },
    '380px',
    '1400px'
  )};
  ${fluidRange(
    {
      prop: 'margin-bottom',
      fromSize: '30px',
      toSize: '40px',
    },
    '380px',
    '1400px'
  )};
`;

export const H2 = styled.h2`
  font-weight: 100;
  line-height: 1.2em;
  ${fluidRange(
    {
      prop: 'font-size',
      fromSize: '30px',
      toSize: '36px',
    },
    '380px',
    '1400px'
  )};
  margin: 0 0 40px;
`;

export const H3 = styled.h3`
  font-weight: ${({ bold }) => (bold ? '900' : '100')};
  @media (max-width: 999px) {
    font-size: 24px;
    line-height: 28px;
    margin: ${({ margin }) => (margin ? '0 0 20px' : '0')};
  }
  @media (min-width: 1000px) {
    font-size: 30px;
    line-height: 35px;
    margin: ${({ margin }) => (margin ? '0 0 30px' : '0')};
  }
`;

export const P = styled.p`
  @media (max-width: 599px) {
    font-size: 18px;
    line-height: 26px;
    margin: 0 0 26px;
  }
  @media (min-width: 600px) {
    font-size: 22px;
    line-height: 32px;
    margin: 0 0 30px;
  }
`;
