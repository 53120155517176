module.exports = {
  colors: {
    white: '#fff',
    orangeLight: '#FFF7F1',
    orangeMedium: '#FFEFE9',
    peach: '#ffc2a9',
    orangeDark: '#FF4501',
  },
  tilt: 3,
};
