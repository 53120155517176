import React from 'react';
import PropTypes from 'prop-types';

const Email = ({ className }) => (
  <svg viewBox="0 0 24 24" fill="none" className={className}>
    <g
      clipPath="url(#prefix__clip0)"
      stroke="#FF4501"
      strokeWidth={2}
      strokeMiterlimit={10}
    >
      <path d="M22 2L7 14v7l3.6-4.3" />
      <path d="M2 10l20-8-4 20L2 10z" strokeLinecap="square" />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default Email;

Email.propTypes = {
  className: PropTypes.string,
};
