import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import styled from 'styled-components';
import { darken } from 'polished';

const Wrapper = styled.div`
  color: ${({ theme, color }) =>
    color === 'white' ? theme.colors.orangeDark : theme.colors.white};
  background: ${({ theme, color }) =>
    color === 'orange' ? theme.colors.orangeDark : theme.colors.white};
  appearance: none;
  border-radius: 80px;
  text-align: center;
  border: 0;
  white-space: nowrap;
  font-weight: 100;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  transition: background 0.2s ease, transform 0.1s ease;
  &:hover {
    background: ${({ theme, color }) =>
      color === 'white'
        ? theme.colors.orangeMedium
        : darken(0.05, theme.colors.orangeDark)};
  }
  &:active {
    transform: scale(0.975);
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }
  @media (max-width: 999px) {
    font-size: 22px;
    line-height: 60px;
    padding: 0 30px;
  }
  @media (min-width: 1000px) {
    font-size: 26px;
    line-height: 80px;
    padding: 0 50px;
  }
`;

const getElement = (href, onClick, to) => {
  if (href) return 'a';
  if (onClick) return 'button';
  if (to) return Link;
  return 'div';
};

export default function Button({
  className,
  children,
  href,
  onClick,
  target,
  type,
  to,
  color,
}) {
  return (
    <Wrapper
      color={color}
      as={getElement(href, onClick, to)}
      className={className}
      href={href}
      onClick={onClick}
      target={target}
      to={to}
      type={type}
    >
      {children}
    </Wrapper>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.string.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.bool,
  to: PropTypes.string,
  type: PropTypes.string,
};
