import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Grid from '../common/Grid';
import Marque from '../icons/Marque';
import Email from '../icons/Email';
import Instagram from '../icons/Instagram';
import LinkedIn from '../icons/LinkedIn';

import { linkedIn, instagram, email } from '../../../config';

const DESKTOP = 800;

const Wrapper = styled.footer`
  display: grid;
  background: ${({ theme }) => theme.colors.orangeMedium};
  @media (max-width: ${DESKTOP - 1}px) {
    padding: 50px 0 30px;
    grid-gap: 30px;
  }
  @media (min-width: ${DESKTOP}px) {
    padding: 60px 0;
    grid-gap: 50px;
  }
`;

const Inner = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${DESKTOP - 1}px) {
    flex-direction: column-reverse;
  }
`;

const Copyright = styled.span`
  @media (max-width: ${DESKTOP - 1}px) {
    margin-bottom: 30px;
    font-size: 0.875em;
  }
  @media (min-width: ${DESKTOP}px) {
    margin-left: 20px;
  }
`;

const SocialMedia = styled.div`
  display: inline-grid;
  grid-gap: 35px;
  grid-auto-flow: column;
  @media (max-width: ${DESKTOP - 1}px) {
    margin-bottom: 30px;
  }
  svg {
    height: 26px;
  }
`;

const LogoWrapper = styled.div`
  @media (max-width: ${DESKTOP - 1}px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const Footer = ({ siteTitle }) => (
  <Wrapper>
    <Inner>
      <LogoWrapper>
        <Link to="/">
          <Marque />
        </Link>
        <Copyright>
          &copy;&nbsp;
          {`${siteTitle} ${new Date().getFullYear()}. All rights reserved.`}
        </Copyright>
      </LogoWrapper>
      <SocialMedia>
        <a
          title="Email"
          href={`mailto:${email}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Email />
        </a>
        <a
          title="LinkedIn"
          href={`https://www.linkedin.com/company/${linkedIn}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedIn />
        </a>
        <a
          title="Instagram"
          href={`https://www.instagram.com/${instagram}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Instagram />
        </a>
      </SocialMedia>
    </Inner>
  </Wrapper>
);

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

export default Footer;
